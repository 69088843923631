<template>
  <v-dialog
    v-model="dialog"
    persistent
    width="1200"
    @click:outside="clickOutside"
  >
    <template v-slot:activator="{ on, attrs }">
      <p id="pjcaItemForm" v-bind="attrs" v-on="on" @click="openItemForm"></p>
    </template>
    <v-card>
      <v-card-title>
        <span class="text-h5" style="margin: auto">DETAIL BARANG</span>
      </v-card-title>
      <v-form
        v-if="form != null"
        ref="formPjcaDetailItem"
        :disabled="loading || detail.status != 0"
        lazy-validation
        @submit.prevent="saveForm"
      >
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="9" class="d-flex flex-column">
                <p
                  class="text-left"
                  style="
                    margin: 0;
                    font-size: 12px;
                    font-weight: bold;
                    color: rgba(0, 0, 0, 0.5);
                  "
                >
                  Nama Barang
                </p>
                <v-text-field
                  v-if="act == 'update' && form.product != null"
                  :value="`(${form.product.code}) ${form.product.name}`"
                  dense
                  disabled
                  outlined
                  style="font-size: 12px; height: 45px"
                />

                <v-autocomplete
                  v-if="act == 'add'"
                  v-model="form.product"
                  :items="dropdown.payreqItem"
                  clearable
                  outlined
                  item-text="product_name"
                  item-value="product_id"
                  hide-details
                  hide-no-data
                  return-object
                  label=""
                  style="margin: 0"
                  @change="productWatcher"
                  dense
                ></v-autocomplete>
              </v-col>

              <v-col
                cols="12"
                md="3"
                class="d-flex flex-column"
                v-if="act == 'add' && form.product != null"
              >
                <p
                  class="text-left"
                  style="
                    margin: 0;
                    font-size: 12px;
                    font-weight: bold;
                    color: rgba(0, 0, 0, 0.5);
                  "
                >
                  Kode Product
                </p>

                <v-text-field
                  :value="form.product.product_code"
                  dense
                  outlined
                  disabled
                  style="font-size: 12px; height: 45px"
                />
              </v-col>
              <!-- <v-col cols="12" md="3" class="d-flex flex-column"></v-col> -->

              <v-col cols="12" md="4" class="d-flex flex-column">
                <p
                  class="text-left"
                  style="
                    margin: 0;
                    font-size: 12px;
                    font-weight: bold;
                    color: rgba(0, 0, 0, 0.5);
                  "
                >
                  Harga Sebelum
                </p>

                <v-text-field
                  v-model="form.price_request"
                  dense
                  outlined
                  disabled
                  type="number"
                  min="0"
                  style="font-size: 12px; height: 45px"
                />
              </v-col>
              <v-col cols="12" md="4" class="d-flex flex-column">
                <p
                  class="text-left"
                  style="
                    margin: 0;
                    font-size: 12px;
                    font-weight: bold;
                    color: rgba(0, 0, 0, 0.5);
                  "
                >
                  Qty Sebelum
                </p>

                <v-text-field
                  v-model="form.qty_request"
                  dense
                  outlined
                  type="number"
                  min="0"
                  disabled
                  style="font-size: 12px; height: 45px"
                />
              </v-col>
              <v-col cols="12" md="4" class="d-flex flex-column"></v-col>
              <v-col cols="12" md="4" class="d-flex flex-column">
                <p
                  class="text-left"
                  style="
                    margin: 0;
                    font-size: 12px;
                    font-weight: bold;
                    color: rgba(0, 0, 0, 0.5);
                  "
                >
                  Harga Rilis
                </p>

                <v-text-field
                  v-model="form.price_done"
                  dense
                  outlined
                  type="number"
                  min="0"
                  style="font-size: 12px; height: 45px"
                />
              </v-col>
              <v-col cols="12" md="4" class="d-flex flex-column">
                <p
                  class="text-left"
                  style="
                    margin: 0;
                    font-size: 12px;
                    font-weight: bold;
                    color: rgba(0, 0, 0, 0.5);
                  "
                >
                  Qty Rilis
                </p>

                <v-text-field
                  v-model="form.qty_done"
                  dense
                  outlined
                  type="number"
                  min="0"
                  :max="form.qty_request"
                  style="font-size: 12px; height: 45px"
                />
              </v-col>
              <v-col cols="12" md="4" class="d-flex flex-column">
                <p
                  class="text-left"
                  style="
                    margin: 0;
                    font-size: 12px;
                    font-weight: bold;
                    color: rgba(0, 0, 0, 0.5);
                  "
                >
                  Satuan
                </p>

                <v-autocomplete
                  v-model="form.uom"
                  :items="dropdown.unit"
                  item-text="name"
                  item-value="id"
                  style="font-size: 12px; height: 45px"
                  return-object
                  dense
                  outlined
                  disabled
                  clearable
                ></v-autocomplete>
              </v-col>

              <v-col cols="12" md="4" class="d-flex flex-column">
                <p
                  class="text-left"
                  style="
                    margin: 0;
                    font-size: 12px;
                    font-weight: bold;
                    color: rgba(0, 0, 0, 0.5);
                  "
                >
                  Discount (%)
                </p>

                <v-text-field
                  v-model="form.discount_percent"
                  @change="discPercentWatcher"
                  dense
                  outlined
                  type="number"
                  min="0"
                  style="font-size: 12px; height: 45px"
                />
              </v-col>
              <v-col cols="12" md="4" class="d-flex flex-column">
                <p
                  class="text-left"
                  style="
                    margin: 0;
                    font-size: 12px;
                    font-weight: bold;
                    color: rgba(0, 0, 0, 0.5);
                  "
                >
                  Discount Amount
                </p>

                <v-text-field
                  v-model="form.discount_amount"
                  @change="discAmountWatcher"
                  dense
                  outlined
                  type="number"
                  min="0"
                  style="font-size: 12px; height: 45px"
                />
              </v-col>
              <v-col cols="12" md="4" class="d-flex flex-column">
                <p
                  class="text-left"
                  style="
                    margin: 0;
                    font-size: 12px;
                    font-weight: bold;
                    color: rgba(0, 0, 0, 0.5);
                  "
                >
                  Pajak
                </p>
                <v-autocomplete
                  v-model="form.tax_type"
                  :items="dropdown.tax"
                  item-text="name"
                  item-value="id"
                  style="font-size: 12px; height: 45px"
                  return-id
                  dense
                  outlined
                  clearable
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" class="d-flex flex-column">
                <p
                  class="text-left"
                  style="
                    margin: 0;
                    font-size: 12px;
                    font-weight: bold;
                    color: rgba(0, 0, 0, 0.5);
                  "
                >
                  Total
                </p>

                <v-text-field
                  :value="calculateTotalPrice()"
                  dense
                  outlined
                  disabled
                  style="font-size: 12px; height: 45px"
                />
              </v-col>
              <v-col cols="12" class="d-flex flex-column">
                <p
                  class="text-left"
                  style="
                    margin: 0;
                    font-size: 12px;
                    font-weight: bold;
                    color: rgba(0, 0, 0, 0.5);
                  "
                >
                  Keterangan
                </p>
                <v-textarea
                  v-model="form.description"
                  dense
                  outlined
                  rows="3"
                  style="font-size: 12px"
                />
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <div style="padding: 0 25px 20px; width: 100%">
            <v-btn
              style="width: 100%"
              color="blue darken-1"
              class="white--text"
              type="submit"
              :loading="loading"
              :disabled="loading"
            >
              SIMPAN
            </v-btn>
            <v-btn
              style="width: 100%; margin-top: 15px"
              color="pink"
              class="white--text"
              type="button"
              @click="closeDialog"
              :loading="loading"
            >
              BATAL
            </v-btn>
          </div>
          <!-- <v-btn color="blue darken-1" text type="submit"> Save </v-btn> -->
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from 'axios'
export default {
  name: 'pjcaItemForm',
  props: ['detail', 'itemDetail', 'act', 'proc'],
  data() {
    return {
      dialog: false,
      form: null,
      rules: {
        productRules: [],
        qtyDoneRules: [],
        priceDoneRules: []
      },
      dropdown: {
        unit: [],
        payreqItem: [],
        tax: [
          {
            id: 3,
            name: 'PPn 11% Inc'
          },
          {
            id: 4,
            name: 'PPn 11% Exc'
          },
          {
            id: 5,
            name: 'PPh 22 (0,3%)'
          }
        ]
      },
      loading: false
    }
  },
  computed: {},
  mounted() {},
  methods: {
    onResize() {
      this.wWidth = window.innerWidth
    },
    showMsgDialog(pModalType, pStatusMsg, pBtnCancel) {
      return new Promise((resolve) => {
        this.$swal
          .fire({
            position: 'center',
            icon: pModalType,
            html: '<strong style="font-size:18px;">' + pStatusMsg + '</strong>',
            showConfirmButton: true,
            showCancelButton: pBtnCancel,
            cancelButtonText: 'No'
          })
          .then((r) => {
            resolve(r)
          })
      })
    },
    discPercentWatcher() {
      if (
        this.form.discount_percent != null &&
        this.form.discount_percent != 0
      ) {
        const disc = this.form.discount_percent
        const price = this.form.price_request
        this.form.discount_amount = price * (disc / 100)
      }
    },
    discAmountWatcher() {
      if (this.form.discount_amount != null && this.form.discount_amount != 0) {
        this.form.discount_percent = 0
      }
    },
    dropdownUnit() {
      console.log('hereee>>.', this.proc)

      axios
        .get(`${this.proc}master/unit/drop_down?keyword=`)
        .then((res) => {
          this.dropdown.unit = res.data.data
        })
        .catch((err) => {
          console.log(err)
        })
    },
    openItemForm() {
      console.log('hallo world', this.detail)
      let processedQty = 0
      if (this.act == 'add') {
        this.form = {
          act: this.act,
          qty_done: 0,
          price_done: 0,
          product: null,
          uom: null,
          discount_amount: 0,
          discount_percent: 0,
          tax_type: null,
          description: '',
          processed: processedQty
        }
        this.getPayreqItemDropdown()
      } else if (this.act == 'update') {
        processedQty = this.calulateTotalQty(this.itemDetail.prd_id)
        this.form = {
          act: this.act,
          product: {
            id: this.itemDetail.product_id,
            code: this.itemDetail.product_code,
            name: this.itemDetail.product_name
          },
          uom: {
            id: this.itemDetail.uom_id,
            name: this.itemDetail.uom_name
          },
          qty_request: this.itemDetail.qty_request,
          price_request: this.itemDetail.price_request,
          qty_done: this.itemDetail.qty_done,
          price_done: this.itemDetail.price_done,
          discount_amount: this.itemDetail.discount_amount,
          discount_percent: this.itemDetail.discount_percent,
          tax_type: this.itemDetail.tax_type,
          description: this.itemDetail.description,
          processed: processedQty - this.itemDetail.qty_done
        }
      }
      this.dropdownUnit()
    },
    // clear() {
    //   this.$emit('loadPage')
    // },
    clickOutside() {
      //   this.closeDialog()
    },
    closeDialog() {
      this.dialog = false
      setTimeout(() => {
        this.form = null
      }, 200)
    },
    saveForm() {
      this.rules.qtyDoneRules = [
        (v) => !!v || 'Wajib diisi',
        v => v > 0 || 'Tidak boleh kosong',
        v => v <= this.form.qty_requset - this.form.processed || `Melebihi batas, ( Qty tersedia = ${this.form.qty_request - this.form.processed})`
      ]
      this.rules.priceDoneRules = [(v) => !!v || 'Wajib diisi']
      this.rules.productRules = [(v) => !!v || 'Wajib diisi']
      const self = this
      setTimeout(function () {
        if (self.$refs.formPjcaDetailItem.validate()) {
          self.createNewForm()
        }
      })
    },
    createNewForm() {
      const newForm = {
        act: this.form.act,
        pjca_id: this.detail.id,
        qty_done: Number(this.form.qty_done),
        price_done: Number(this.form.price_done),
        discount_amount: Number(this.form.discount_amount),
        discount_percent: Number(this.form.discount_percent),
        tax_type: this.form.tax_type,
        description: this.form.description
      }
      if (this.form.act == 'add') {
        Object.assign(newForm, {
          product_id: this.form.product.product_id,
          product_code: this.form.product.product_code,
          product_name: this.form.product.product_name,
          uom_id: this.form.uom_id,
          uom_name: this.form.uom_name,
          qty_request: this.form.qty_request,
          price_request: this.form.price_request
        })
      }
      if (this.form.act == 'update') {
        Object.assign(newForm, {
          id: this.itemDetail.id
        })
      }
      console.log(newForm)
      this.submitDetailItem(newForm)
    },
    async submitDetailItem(form) {
      this.loading = true
      await axios
        .post(`${this.proc}pjca/detail/save`, form)
        .then((res) => {
          console.log(res.data)
          this.loading = false
          if (res.data.status_code == '00') {
            this.dialog = false
            setTimeout(() => {
              this.$emit('loadPage')
            }, 200)
          } else {
            if (res.data.status_msg === 'Parameter value has problem') {
              this.showMsgDialog(
                'warning',
                'Invalid input, Parameter value has problem',
                false
              )
            } else {
              this.showMsgDialog('warning', res.data.status_msg, false)
            }
          }
        })
        .catch((err) => {
          console.log(err)
          this.loading = false
          this.showMsgDialog(
            'error',
            err
              ? 'Something went wrong, Please contact an admin!'
              : 'Something went wrong, Please contact an admin!',
            false
          )
        })
    },
    getPayreqItemDropdown() {
      const url = `${this.proc}payreq/detail/${this.detail.payment_request.id}`
      // url = url + '&order_by=prd.id&order_type=ASC'
      axios
        .get(url)
        .then((res) => {
          if (res.data.status_code == '00') {
            if (res.data.data.payment_request_detail != null) {
              this.dropdown.payreqItem = res.data.data.payment_request_detail
              console.log('payreq detail item>>>', this.dropdown.payreqItem)
            }
          }
        })
        .catch((err) => {
          console.log(err)
        })
    },
    calculateTotalPrice() {
      let total = 0
      const qty = Number(this.form.qty_done != null ? this.form.qty_done : 0)
      const price = Number(
        this.form.price_done != null ? this.form.price_done : 0
      )
      const discount_amount = Number(
        this.form.discount_amount != null ? this.form.discount_amount : 0
      )

      const discount_precent = Number(
        this.form.discount_percent != null ? this.form.discount_percent : 0
      )

      const tax = Number(this.form.tax != null ? this.form.tax : 0)

      const calc_before_discount = qty * price + tax
      // let calc_after_discount = 0

      if (discount_amount != 0 && discount_amount != null) {
        total = calc_before_discount - discount_amount
      } else if (discount_precent != 0 && discount_amount != null) {
        total =
          calc_before_discount - (calc_before_discount * discount_precent) / 100
      } else {
        total = calc_before_discount
      }

      return total
    },
    productWatcher(item) {
      console.log('item>>>', item)
      if (item != null) {
        this.form.qty_request = item.qty_request
        this.form.price_request = item.price_request
        this.form.uom = {
          id: item.uom_id,
          name: item.uom_name
        }
      }
    },
    calulateTotalQty(xPrd_id) {
      let total = 0
      const xArrPyrItem = this.detail.pjca_detail.filter(({ prd_id }) => prd_id == xPrd_id)
      total = xArrPyrItem.reduce((accum, item) => accum + item.qty_done, 0)

      return total
    }
  }
}
</script>
